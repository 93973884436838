<template>
    <v-btn block color="primary" :to="{ name: 'invitation-accept', params: { invid: this.$route.params.invid } }">continue</v-btn>
</template>

<script>
export default {
    name: 'InvitationAuthenticated',
    props: {},
    data() {
        return {}
    },
    computed: {},
    methods: {}
}
</script>
